import { App } from "../App"
import { EventEmitter } from "../Utils/EventEmitter"
import { Utils } from "../Utils/Utils"
import UIMaterialSelector from "./Screen/UIMaterialSelector"
import UIScreenButton from "./Screen/UIScreenButton"
import UI3DKnifeCloseButton from "./3D/UI3DKnifeCloseButton"
import UI3DKnifeOpenButton from "./3D/UI3DKnifeOpenButton"
import { Vector2, Vector3 } from "three"

import gsap from 'gsap'

export default class UIManager extends EventEmitter {
    constructor(container) {
        super()

        this.container = container
        
        this.mainWorld = null
        this.mainScreen = null

        this.closeButton = null
        this.openButton = null

        this.screenMenuVisible = true

        this.app = new App()
        
        this.toggleOpenCloseBound = this.toggleOpenClose.bind(this)

        this.init()
    }
    
    init() {
        this.initScreen()
        this.initWorld()

        const onResize = (aspect, width, height, animDuration) => {
            const duration = typeof animDuration === 'number' ? animDuration : 0.8
            // console.log(`UIManager :: resize ${aspect}`)

            const hideUI = aspect < 1.5 || width < 1800 || height < 1024

            if (hideUI && this.screenMenuVisible) {
                this.hideScreenMenu(duration)
            }
            else if (!hideUI && !this.screenMenuVisible) {
                this.showScreenMenu(duration)
            }
        }

        // Hack : display screen menu only when aspect is larger than 1.5 (cheap responsive for demo purpose)
        this.app.renderSize.on('resize', (size) => { const {aspect, width, height} = size; onResize(aspect, width, height) })
        onResize(this.app.renderSize.aspect, this.app.renderSize.width, this.app.renderSize.height, 0.08)
    }
    
    initScreen() {
        this.mainScreen = document.querySelector('.ui-screen')
        this.colorSelector = new UIMaterialSelector(this, '.material-selector-button')
        this.openCloseButton = new UIScreenButton(this, '.open-close-button', this.toggleOpenCloseBound)
    }
    
    initWorld() {
        this.mainWorld = document.querySelector('.ui-3d')
        
        // this.closeButton = new UI3DKnifeCloseButton(this, 'ui-close', new Vector3(-2.0, -1.5, 1.0), true)
        // this.closeButton = new UI3DKnifeCloseButton(this, 'ui-close', new Vector3(0, -2, 1.0), true)

        // this.closeButton = new UI3DKnifeCloseButton(this, 'ui-close', new Vector2(0.0, -3), true)        
        // this.openButton = new UI3DKnifeOpenButton(this, 'ui-open', new Vector2(0.0, 1.0), true)
        // this.closeButton = new UI3DKnifeCloseButton(this, 'ui-close', new Vector2(0.35, 0.0), true)        
        // this.openButton = new UI3DKnifeOpenButton(this, 'ui-open', new Vector2(0.8, 1.8), true)
    }

    // Convert world point into screen point to keep DOM objects position relative to 3D space
    worldToScreenPt(worldPt) {
        // const spherical = this.app.camera.instance.
        // console.log(`UIManager :: camera phy `)
        return Utils.worldToScreen(worldPt, this.app.camera.instance, this.app.renderSize.size)
    }
    
    toggleOpenClose() {
        this.trigger('toggleOpenClose')
    }

    closeKnife() {
        this.trigger('closeKnife')
        // this.closeButton.instance.style.opacity = 0
    }
    
    openKnife() {
        this.trigger('openKnife')
        // this.openButton.instance.style.opacity = 0
    }
    
    // showOpenCloseButtons() {
    //     // this.closeButton.instance.style.opacity = 1
    //     // this.openButton.instance.style.opacity = 1
    // }
    
    // hideOpenCloseButtons() {
    //     // this.closeButton.instance.style.opacity = 0
    //     // this.openButton.instance.style.opacity = 0        
    // // }

    // toggleOpenCloseButtons(open) {
    //     if (open) {
    //         this.closeButton.instance.style.opacity = 1
    //     }
    //     else {
    //         // this.openButton.instance.style.opacity = 1
    //     }
    // }

    changeMaterial(material) {
        this.trigger('changeMaterial', [{material}])
    }

    update() {
        // this.closeButton.update()
        // this.openButton.update()
    }

    showScreenMenu(animDuration) {
        this.screenMenuVisible = true
        this.mainScreen.style.visibility = "visible"
        if (typeof animDuration === 'number') {
            gsap.to(this.mainScreen, {css: {opacity: 1}, duration: animDuration, ease: "sine.easeInOut"})
        }
        else {
            this.mainScreen.style.opacity = 1
        }
    }

    hideScreenMenu(animDuration) {
        this.screenMenuVisible = false
        if (typeof animDuration === 'number') {
            gsap.to(this.mainScreen, {css: {opacity: 0}, duration: animDuration, ease: "sine.easeInOut"})
        }
        else {
            this.mainScreen.style.visibility = "hidden"
            this.mainScreen.style.opacity = 0
        }
    }

    destroy() {
        this.toggleOpenCloseBound = null

        this.container.destroy()
        this.container = null

        this.closeButton.destroy()
        this.closeButton = null
        
        this.openButton.destroy()
        this.openButton = null

        this.mainScreen = null
        this.mainWorld = null

        this.app = null
    }
}
